import axios from 'axios'
import {
  Toast,
  Notify
} from 'vant'
import store from '@/store'

//process.env.VUE_APP_BASE_API
const service = axios.create({
  baseURL: process.env.NODE_ENV != 'development' ?
    'https://xyrq.coolsb.cn/prod-api' : '', // url = base url + request url
  timeout: 60000, // request timeout
})

service.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token')
    console.log('token', token)
    if (token) {
      config.headers['token'] = token
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code != 200) {
      console.log('接口信息报错', res.msg)
      Notify(res.msg)
      return res
    } else {
      return res
    }
  },
  error => {
    console.log('接口信息报错' + error)
    return Promise.reject(error)
  },
)

export default service